.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  margin: 1rem 0;
  .divider-text {
    position: relative;
    display: inline-block;
    padding: 0 1rem;
    background-color: #fff;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      border-top: 1px solid #dfe3e7;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
  &.divider-left .divider-text {
    left: 0;
    float: left;
    padding-left: 0;
  }
  &.divider-left-center .divider-text {
    left: -25%;
  }
  &.divider-right-center .divider-text {
    left: 25%;
  }
  &.divider-right .divider-text {
    float: right;
    padding-right: 0;
  }
}
