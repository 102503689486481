.btn {
  i,
  svg {
    width: 16px;
    height: 16px;
  }
  &.icon {
    padding: 0.4rem 0.6rem;
    svg {
      width: 16px;
      height: 16px;
    }
    &.icon-left {
      svg {
        margin-right: 3px;
      }
    }
    &.icon-right {
      svg {
        margin-left: 3px;
      }
    }
  }
  &.btn-outline-white {
    color: #fff;
    border-color: #fff;
    &:hover {
      color: #333;
      background-color: #fff;
    }
  }

  @each $key, $value in $theme-colors-light {
    &.btn-light-#{$key} {
      background-color: $value;
      color: darken($value, 80%);
    }
  }

  @each $key, $value in $theme-colors {
    &.btn-#{$key} {
      @if $key == 'light' {
        color: $body-color;
      } @else {
        color: #fff;
      }
    }
  }
}
.btn-block {
  width: 100%;
}
.btn-group {
  &:not(.dropdown) .btn:not([class*='btn-']) {
    border: 1px solid #dfe3e7;
  }
  & > .btn {
    border-radius: 0.267rem;
  }
}
.buttons {
  .btn {
    margin: 0 10px 10px 0;
  }
}

.btn-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
button[class^='btn-action'] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  margin: 0 5px;
  border: none;
  outline: none;
  transition: all ease-in-out 0.3s;
  i {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
  }
}
.btn-action-primary {
  background-color: #1e90ff33;
  color: #1e90ff;
}
.btn-action-danger {
  background-color: #ff634733;
  color: #ff6347;
}
.btn-action-active {
  background-color: #00800033;
  color: #008000;
}
.btn-action-inactive {
  background-color: #80808033;
  color: #808080;
}
.btn-action-ghost {
  background-color: #ffffff;
  color: #363636;
}
.btn-action-primary:hover {
  background-color: #1e90ff;
}
.btn-action-active:hover {
  background-color: #008000;
}
.btn-action-inactive:hover {
  background-color: #808080;
}
.btn-action-danger:hover {
  background-color: #ff6347;
}

button[class^='btn-action']:hover {
  color: #ffffff;
}

.btn-action-ghost:hover {
  color: #000000 !important;
}
