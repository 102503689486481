.btn {
  .badge {
    border-radius: 50%;
    margin-left: 5px;
    &.bg-transparent {
      background-color: rgba(255, 255, 255, 0.25) !important;
      color: #fff;
    }
  }
}
a.badge:hover {
}
