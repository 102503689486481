.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.message {
  font-size: 18px;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}
