#sidebar {
  &.active {
    .sidebar-wrapper {
      left: 0;
    }
  }
  &:not(.active) {
    .sidebar-wrapper {
      left: -300px;
    }
    & ~ #main {
      margin-left: 0;
    }
  }
}
.sidebar-wrapper {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  bottom: 0;
  transition: left 0.5s ease-out;
  .sidebar-header {
    padding: 2rem 2rem 1rem;
    font-size: 2rem;
    font-weight: bold;

    img {
      height: 2.2rem;
    }
  }
  .sidebar-toggler.x {
    position: absolute;
    right: 0;
    top: 0.5rem;
    display: none;
  }

  .menu {
    margin-top: 2rem;
    padding: 0 2rem;
    font-weight: 600;
    .sidebar-title {
      padding: 0 1rem;
      margin: 1.5rem 0 1rem;
      font-size: 1rem;
      list-style: none;
      font-weight: 600;
      color: #25396f;
    }

    .sidebar-link {
      padding: 0.7rem 1rem;
      font-size: 1rem;
      display: flex;
      align-items: center;
      border-radius: 0.5rem;
      transition: all 0.5s;
      text-decoration: none;
      color: #25396f;
      svg,
      i {
        color: #7c8db5;
      }
      span {
        margin-left: 1rem;
      }
      &:hover {
        background-color: rgb(240, 241, 245);
      }
    }
    .sidebar-item {
      list-style: none;
      margin-top: 0.5rem;
      position: relative;
      &.has-sub {
        .sidebar-link:after {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:gray;stroke-width:1"></polyline></svg>');
          position: absolute;
          color: #ccc;
          right: 15px;
          top: 12px;
          display: block;
        }
      }
      .sidebar-link {
        &.active {
          background-color: $primary;
          span {
            color: #fff;
          }
          svg,
          i {
            fill: white;
            color: white;
          }

          &.has-sub:after {
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:#fff;stroke-width:1"></polyline></svg>');
          }
        }
      }
    }
    .submenu {
      list-style: none;
      display: none;
      transition: max-height 2s cubic-bezier(0, 0.55, 0.45, 1);
      overflow: hidden;
      &.active {
        max-height: 999px;
        display: block;
      }
      .submenu-item {
        &.active {
          position: relative;
          & > a {
            color: $primary;
            font-weight: bold;
          }
        }
        a {
          padding: 0.7rem 2rem;
          display: block;
          color: $headings-color;
          font-size: 0.85rem;
          font-weight: 600;
          letter-spacing: 0.5px;
          transition: all 0.3s;
          &:hover {
            margin-left: 0.3rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .sidebar-wrapper {
    position: absolute;
    left: -300px;
    .sidebar-toggler.x {
      display: block;
    }
  }
}
@media screen and (min-width: 1200px) {
}
