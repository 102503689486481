@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import '~antd/dist/antd.less';
* {
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;
}
.ant-form-item-explain-error {
  font-size: 12px;
  margin: 5px 0 10px;
}
.ant-steps-item-description {
  color: black !important;
}
.ant-steps-item-title {
  color: black !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d9d9d9;
}
.react-pdf__Document {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2.5rem;
  background-color: #e3e3e3;
  width: 100%;
}
.react-pdf__Page {
  border: 1px solid rgba(191, 191, 191, 0.545);
  border-radius: 5px;
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ql-toolbar.ql-snow {
  min-height: 57px;
  border-color: #dee2e6 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  display: flex;
  align-items: center;
}
.fs-7 {
  font-size: 14px;
}
.hover-style:hover {
  background-color: rgba(30, 143, 255, 0.08) !important;
}
.jodit-workplace {
  height: 100% !important;
}
.jodit-react-container {
  height: 100% !important;
  all: unset;
}
.jodit-react-container * {
  color: black;
}
.jodit-react-container a {
  color: blue !important;
}

@primary-color: #000000;@link-color: #000000;@item-active-bg: #fafafa;@success-color: #000000;@warning-color: #000000;@error-color: #f5222d;@border-radius-base: 0;@border-color-base: #d9d9d9;@collapse-header-padding: 0px;@alert-info-bg-color: #fff;@layout-body-background: #fff;@layout-header-background: #fff;@select-item-selected-bg: #bfbfbf;