/*
* Mazer, Technology Innovations Bootstrap HTML5 Landing Page
* 
* File: app.scss
*
* If you want to edit the components, just go to _variables.scss
*/

/**

====== TABLE OF CONTENTS =======
1. Bootstrap & Variables Inside
2. Bootstrap Components Override 
    2.1 Alert
    2.2 Avatar
    2.3 Badge
    2.4 Buttons
    2.5 Breadcrumb
    2.6 Carousel
    2.7 Card
    2.8 Divider
    2.9 Dropdowns
    2.10 Forms
    2.11 Modal
    2.12 Sidebar
    2.13 Navs
    2.14 Navbar
    2.15 Pagination
    2.16 Table
    2.17 Progress
4. Pages
    4.0 Layout
    4.1 Auth
    4.2 Error
5. Utilities


*/

// 1. Bootstrap
@import './assets/scss/variables';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';

// 2. Bootstrap Components Override

@import './assets/scss/antd';

// - 2.1. Alert
@import './assets/scss/components/alert';

// - 2.2. Avatar
@import './assets/scss/components/avatar';

// - 2.3. Badge
@import './assets/scss/components/badge';

// - 2.4. Buttons
@import './assets/scss/components/buttons';

// - 2.5. breadcrumb
@import './assets/scss/components/breadcrumb';

// - 2.6. carousel
@import './assets/scss/components/carousel';

// - 2.7. card
@import './assets/scss/components/card';

// - 2.8. divider
@import './assets/scss/components/divider';

// - 2.9. dropdowns
@import './assets/scss/components/dropdowns';

// - 2.10. forms
@import './assets/scss/components/forms';

// - 2.11. modal
@import './assets/scss/components/modal';

// - 2.12. sidebar
@import './assets/scss/components/sidebar';

// - 2.13. navs
@import './assets/scss/components/navs';

// - 2.14. navbar
@import './assets/scss/components/navbar';

// - 2.15. pagination
@import './assets/scss/components/pagination';

// - 2.16. table
@import './assets/scss/components/table';

// - 2.17. progress
@import './assets/scss/components/progress';

// - 2.18. icons
@import './assets/scss/components/icons';

// 4.0 Layout
@import './assets/scss/layouts/main.scss';
@import './assets/scss/utilities';
