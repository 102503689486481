.upgrade-to-prime-btn {
  background: #000;
  color: #fff;
  border: 0;
  outline: 0;
  padding: 10px 20px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  white-space: nowrap;
}
