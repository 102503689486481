.ant-btn {
  border-radius: 5px;
  font-weight: 600;
}
.ant-menu-item,
.ant-card,
.ant-table,
.ant-modal-content,
.ant-modal-header {
  border-radius: 10px;
}
@media screen and (max-width: 900px) {
  .ant-table {
    max-width: 700px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 700px) {
  .ant-table {
    max-width: 500px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 500px) {
  .ant-table {
    max-width: 400px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 420px) {
  .ant-table {
    max-width: 350px;
    overflow-x: auto;
  }
}
